import React, { useEffect, useState } from 'react';
// import scss
import './Intro.scss';
// import hero image
import hero from '../../images/headshot-hero.jpg';
//import ScrollingText from '../ScrollingText/ScrollingText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';


export const Intro = ({ advanceSlide }) => {

    const [arrowClass, setArrowClass] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            setArrowClass('flash-animation');
        }, 4000); // Starts the animation 4 seconds after component mounts

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
        <div className="Intro container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            {/* <ScrollingText /> */}
            <div className="row">
                <div className="col-12 col-md-7 d-flex justify-content-center align-items-center">
                    <div>
                        <h1>Scott Andersen</h1>
                        <h2>Full Stack Developer</h2>
                        <p>I have 16 years of experience developing Websites and Platforms.
                            I am a full stack developer with a passion for solving problems and saving people time.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-5">
                    <img src={hero} alt="Scott Andersen" className="img-fluid rounded img_border" />
                </div>
            </div>
            <div className="arrow-container">
                <FontAwesomeIcon icon={faArrowDown} className={arrowClass} onClick={advanceSlide} />
            </div>
        </div>
    )
};
