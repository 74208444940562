import React, { useState } from 'react';
import { PageSlides, SlideParallaxType } from '@re_point/react-page-slides';
import './FullPageSlide.scss';
import { Intro } from '../Intro/Intro';
import { Slide2 } from '../Slides/Slide2/Slide2';
import { Slide3 } from '../Slides/Slide3/Slide3';
import PortfolioSlide from '../Slides/PortfolioSlide/PortfolioSlide';

import { Outro } from '../Outro/Outro';
import milkyWay from '../../images/milky-way-bg.jpg';
import tetons from '../../images/tetons-mormon-row.jpg';
import yellowstone from '../../images/yellowstone.jpg';
import lighthouse from '../../images/yellowstone-prismatic.jpg';

import port_1_img from "../../images/dtb-mobile-in-hand-mockup.jpg";
import port_2_img from "../../images/wordpress-sites.jpg";
import port_3_img from "../../images/storylineweaver.jpg";


export const FPS = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const goToNextSlide = () => {
        setCurrentSlideIndex(currentIndex => currentIndex + 1);
    };



    

    const port_1_title = "Taking Print Digital";
    const port_1_technologies = ["Vue 3", "Custom CMS", "PHP", "Flask API", "Python", "MySQL"];
    const port_1_desc = "As the developer behind Skurnik's transformative Digital Tasting Book, I'm proud to have played a pivotal role in revolutionizing the way we experience in-person tasting events. By transitioning from traditional, bulky print books to a sleek, digital format built on Vue 3, we not only significantly reduced our print budget and environmental footprint but also enhanced the overall user experience. The digital book, integrated with a custom-built API that seamlessly communicates with our live website and inventory systems, is managed through a Custom CMS tailored specifically for administering tastings. This innovation allows attendees to use their smartphones or tablets to effortlessly navigate our events, from the grand showcases at the Met Pavilion to intimate tastings across the country. With features like detailed technical information, advanced search and filter options, and the ability to make notes and lists on the go, our Digital Tasting Book simplifies the tasting experience while preserving the ease of use and richness of information that our attendees have come to expect.";
    const port_1_mob_desc = "As the creator of Skurnik's Digital Tasting Book, I revolutionized tasting events by shifting from print to a Vue 3 digital format. This move slashed printing costs and environmental impact, while improving user experience. The book, integrated with a custom API for live updates from our inventory, is managed through a specialized CMS, allowing event attendees to use their devices to easily navigate events, access detailed information, and personalize their experience with advanced search, notes, and lists.";
    
    const port_2_title = "Countless Wordpress Sites";
    const port_2_technologies = ["PHP", "Javascript", "SASS", "WordPress", "Salesforce", "Yardi", "Navision", "API Integrations"];
    const port_2_desc = "Throughout my career, I've developed a multitude of WordPress sites, each tailored to meet diverse business needs. However, what truly sets my work apart is the intricate integrations I've coded to bridge these sites with pivotal external systems. By connecting WordPress platforms to Salesforce, I've empowered businesses to streamline their customer relationship management, ensuring a seamless flow of data between their website and CRM. Integrating with Yardi has revolutionized the way real estate clients manage properties, offering them a unified dashboard that blends site content with critical property data. Furthermore, my work with Navision has transformed inventory and pricing management for e-commerce sites, allowing for real-time updates and enhanced operational efficiency. These integrations not only enhance the functionality of the WordPress sites but also significantly improve business processes, driving efficiency, and providing a cohesive user experience.";
    const port_2_mob_desc = "In my career, I've specialized in developing gorgeous WordPress sites with unique integrations, connecting them to key external systems like Salesforce, Yardi, and Navision. This work streamlines CRM processes, revolutionizes real estate management with unified dashboards, and transforms e-commerce by updating inventory and pricing in real time. My integrations enhance site functionality, streamline business operations, and improve user experiences.";


    const port_3_title = "AI-Powered Storytelling";
    const port_3_technologies = ["React", "Python", "PostgreSQL", "Django", "LangChain", "OpenAI"];
    const port_3_desc = "AI is set to be the linchpin in the evolution of technology, and I'm at the forefront of this shift, integrating AI into three distinct projects for various clients to unlock new possibilities. A standout example is Storyline Weaver, a \"Choose Your Own Adventure\" style platform that showcases the seamless blend of AI with interactive storytelling. By utilizing OpenAI's advanced models, I've created a space where users can actively shape their narrative, making each decision pivotal to the unfolding story. This project not only highlights my commitment to leveraging AI but also illustrates its potential to create deeply personalized and engaging experiences. Storyline Weaver is a testament to the innovative ways AI can be woven into the fabric of digital interactions, offering users not just a story but a journey they control, powered by the latest in AI technology.";
    const port_3_mob_desc = "I'm pioneering the integration of AI in technology, notably through the Storyline Weaver project, a \"Choose Your Own Adventure\" platform. Using OpenAI's models, it enables users to shape their stories, demonstrating AI's potential for personalized, interactive experiences. Storyline Weaver exemplifies how AI can transform digital interactions, giving users control over their journey with cutting-edge AI.";
    

    const slides = [
        {
            content: <Intro advanceSlide={goToNextSlide} />,
            style: {
                backgroundImage: 'url("' + milkyWay + '")'
            }
        },
        {
            content: <Slide2 />,
            style: {
                backgroundImage: 'url("' + tetons + '")'
            }
        },
        {
            content: <Slide3 advanceSlide={goToNextSlide} />,
            style: {
                backgroundImage: 'url("' + yellowstone + '")'
            }
        },
        {
            content: <PortfolioSlide title={port_1_title} img={port_1_img} technologies={port_1_technologies} desc={port_1_desc} mob_desc={port_1_mob_desc} />,
            style: {
                backgroundImage: 'linear-gradient(to right bottom, #942cb1, #832bab, #7329a5, #62279e, #512597)'
            }
        },
        {
            content: <PortfolioSlide title={port_2_title} img={port_2_img} technologies={port_2_technologies} desc={port_2_desc} mob_desc={port_2_mob_desc} />,
            style: {
                backgroundImage: 'linear-gradient(to left, #2cb14f, #00ab8f, #009fc7, #008ce4, #366fd8)'
            }
        },
        {
            content: <PortfolioSlide title={port_3_title} img={port_3_img} technologies={port_3_technologies} desc={port_3_desc} mob_desc={port_3_mob_desc}/>,
            style: {
                backgroundImage: 'linear-gradient(to left bottom, #090537, #0d145c, #181f83, #262bad, #3636d8)'
            }
        },
        {
            content: <Outro />,
            style: {
                backgroundImage: 'url("' + lighthouse + '")'
            }
        },
    ];
    return (
        <PageSlides currentSlideIndex={currentSlideIndex}
            enableAutoScroll={true}
            transitionSpeed={1000}
            slides={slides}
            parallax={{
                offset: 0.6,
                type: SlideParallaxType.reveal
            }}
        />
    )
};