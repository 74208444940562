import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Outro.scss';


import { useFormspark } from "@formspark/use-formspark";

const FORMSPARK_FORM_ID = "WM5dxPpfO";
const TheForm = () => {
    const [submit, submitting] = useFormspark({
      formId: FORMSPARK_FORM_ID,
    });
  
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const onSubmit = async (e) => {
      e.preventDefault();
      await submit({ name, email, message });
      alert("Form submitted");
    };
  
    return (
      <form onSubmit={onSubmit}>
        
        
        <div className="mb-3">
                            <label htmlFor="nameInput" className="form-label">Name</label>
                            <input type="text" name="Name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="nameInput" placeholder="Your name" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="emailInput" className="form-label">Email Address</label>
                            <input type="email" name="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="emailInput" placeholder="name@example.com" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="messageTextarea" className="form-label">Message</label>
                            <textarea className="form-control" name="Message" value={message} onChange={(e) => setMessage(e.target.value)} id="messageTextarea" rows="3" placeholder="Your message"></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={submitting}>Send</button>
      </form>
    );
  };



export const Outro = () => {
    return (
        <div className="Outro container ">
            {/* <ScrollingText /> */}
            <div className="row d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div className="col-12 col-md-6 ">

                    <h1>Get in Touch</h1>
                    <h5>Scott Andersen</h5>

                    <div className="socials">
                        <a href="https://www.linkedin.com/in/scott-andersen-75588475/" className="social-icon" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href="https://twitter.com/_adv3ntr_" className="social-icon" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                        <a href="https://instagram.com/_adv3ntr_" className="social-icon" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>

                </div>
                <div className="col-12 col-md-6 pb-5">
                    <TheForm />
                </div>
            </div>
            <footer>All the photos on the site are mine - I'm a bit of a photobug too!</footer>
        </div>
    )
};
