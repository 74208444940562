import React from 'react';
// import scss
import './Slide2.scss';
// import hero image
//import hero from '../../images/headshot-hero.jpg';


export const Slide2 = () => {


    return (
        <div className="Intro container  d-flex justify-content-center align-items-center" style={{ height: "100vh" }} >





            <div>
                <div className="row" >
                    <div className="col-12 pb-5 text-center slide_introduction">
                        <h2>Experience</h2>
                        <h3>My journey through diverse industries has equipped me with a vast toolkit of technologies and frameworks, allowing me to craft tailored solutions for unique challenges.</h3></div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center ">
                        <div>
                            <h1 className="desktop_only">Code</h1>
                            <h2>Full Stack Developer</h2>
                            <p>With 16 years of hands-on experience, I've developed a knack for building robust Web Platforms. My drive is to innovate solutions that streamline processes, enhance efficiency and drive sales.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center text-md-end">
                        <div>
                            <h1 className="desktop_only">Business</h1>
                            <h2 >Strategic Insight</h2>
                            <p> Leveraging a solid business background, I bridge the gap between technical development and strategic business goals, ensuring that every project not only meets but exceeds business expectations.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
