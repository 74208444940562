import React, { useEffect, useState } from 'react';

import './Slide3.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

export const Slide3 = ({ advanceSlide }) => {
    const [arrowClass, setArrowClass] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            setArrowClass('flash-animation');
        }, 4000); // Starts the animation 4 seconds after component mounts

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);
    return (
        <div className="slide3 container" >




            <div className="row d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>

                <div className="col-12 col-md-8 d-flex justify-content-center align-items-center text-center">
                    <div>

                        <h2>Few things inspire me more than achieving <br /><strong>Business Goals</strong> with <strong>Code</strong>.</h2>
                        <h3 className="mt-5 pt-5">Here are a few projects I've worked on recently:</h3>

                    </div>
                </div>

            </div>
            <div className="arrow-container">
                <FontAwesomeIcon icon={faArrowDown} className={arrowClass} onClick={advanceSlide} />
            </div>
        </div>
    )
};
