import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FPS } from './components/FullPageSlide/FullPageSlide';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/dtbg"
          element={<ExternalRedirect />}
        />
        <Route
          path="/DTBG"
          element={<ExternalRedirect />}
        />
        <Route
          path="/dtbv"
          element={<ExternalRedirect2 />}
        />
        <Route path="/" element={<div className="ReactApp"><FPS /></div>} />
      </Routes>
    </Router>
  );
}

function ExternalRedirect() {
  React.useEffect(() => {
    window.location.href = 'https://drive.google.com/file/d/1IHbrkRBOrnuOSBAiZFvCPlHfUYH0sArR/view?usp=sharing';
  }, []);

  return null;
}

function ExternalRedirect2() {
  React.useEffect(() => {
    window.location.href = 'https://vimeo.com/955468281';
  }, []);

  return null;
}


export default App;
