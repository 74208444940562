import React from 'react';
import './PortfolioSlide.scss';

export default function Slide4(props) {
    return (
        <div className="slide container" >
            <div className="row d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div className="project_image" >
                    <img src={props.img} alt="" className="img-fluid" />
                </div>
                <div className="project_text" >
                    <h2>{props.title}</h2>
                    <p className="desktop_only">{props.desc}</p>
                    <p className="mobile_only">{props.mob_desc}</p>
                    <div className="tech">
                        {props.technologies.map((tech, index) => (
                            <span key={index}>{tech}</span>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
};
